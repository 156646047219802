// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-body {
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px;
}
.page-body .table {
    border: solid 1px rgba(0, 0, 0, 0.07);
    font-size: 14px;
}
.page-body .table th {
    color: #000;
    background: rgba(238, 241, 250, 1);
    font-weight: 500;
    border-bottom-color: rgba(0, 0, 0, 0.07) !important;
}
.page-body .table th:not(:first-child) {
    text-align: center;
}
.page-body .table td {
    border: none;
    border-left: solid 1px rgba(0, 0, 0, 0.07);
}
.page-body .table td:not(:first-child) {
    text-align: center;
}
.btn-primary {
    outline: none;
    background: rgba(25, 40, 84, 1);
    min-width: 132px;
    height: 40px;
}

.page-body th {
    font-size: 14px;
}

/* CSS */
.disabled-button {
    opacity: 0.5; /* Adjust the opacity value as needed */
    cursor: not-allowed; /* Optionally, change the cursor to indicate it's disabled */
  }

`, "",{"version":3,"sources":["webpack://./src/components/Initiate-IFCRA/InitiateIFCRA.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,qCAAqC;IACrC,eAAe;AACnB;AACA;IACI,WAAW;IACX,kCAAkC;IAClC,gBAAgB;IAChB,mDAAmD;AACvD;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,0CAA0C;AAC9C;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,+BAA+B;IAC/B,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA,QAAQ;AACR;IACI,YAAY,EAAE,uCAAuC;IACrD,mBAAmB,EAAE,4DAA4D;EACnF","sourcesContent":[".page-body {\r\n    background: #fff;\r\n    border-radius: 10px;\r\n    padding: 10px 20px;\r\n}\r\n.page-body .table {\r\n    border: solid 1px rgba(0, 0, 0, 0.07);\r\n    font-size: 14px;\r\n}\r\n.page-body .table th {\r\n    color: #000;\r\n    background: rgba(238, 241, 250, 1);\r\n    font-weight: 500;\r\n    border-bottom-color: rgba(0, 0, 0, 0.07) !important;\r\n}\r\n.page-body .table th:not(:first-child) {\r\n    text-align: center;\r\n}\r\n.page-body .table td {\r\n    border: none;\r\n    border-left: solid 1px rgba(0, 0, 0, 0.07);\r\n}\r\n.page-body .table td:not(:first-child) {\r\n    text-align: center;\r\n}\r\n.btn-primary {\r\n    outline: none;\r\n    background: rgba(25, 40, 84, 1);\r\n    min-width: 132px;\r\n    height: 40px;\r\n}\r\n\r\n.page-body th {\r\n    font-size: 14px;\r\n}\r\n\r\n/* CSS */\r\n.disabled-button {\r\n    opacity: 0.5; /* Adjust the opacity value as needed */\r\n    cursor: not-allowed; /* Optionally, change the cursor to indicate it's disabled */\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
