// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-half-bordered th,
.table-half-bordered td {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.table-half-bordered>:not(:last-child)>:last-child>* {
    border-bottom-color: rgba(219, 219, 219, 1);
}

`, "",{"version":3,"sources":["webpack://./src/components/Inherent-Risk/InherentRisk.css"],"names":[],"mappings":"AAAA;;IAEI,2CAA2C;AAC/C;AACA;IACI,2CAA2C;AAC/C","sourcesContent":[".table-half-bordered th,\r\n.table-half-bordered td {\r\n    border-bottom: solid 1px rgba(0, 0, 0, 0.1);\r\n}\r\n.table-half-bordered>:not(:last-child)>:last-child>* {\r\n    border-bottom-color: rgba(219, 219, 219, 1);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
