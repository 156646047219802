// const env = process.env.NODE_ENV || "local";
const env = "development";

const environment = {
  local: {
    apiUrl: "http://localhost:8000",
    clientURL: "http://localhost:3000",
    MAX_FILE_SIZE: 30 * 1024 * 1024
  },
  development: {
    //apiUrl: "http://ifcra-dev.solutionpot.com:8000", //bypass apache url
    apiUrl: 'http://65.0.134.39:8000',
    clientURL: "http://ifcra-dev.solutionpot.com",
    MAX_FILE_SIZE: 30 * 1024 * 1024
  },
  production: {
    apiUrl: "https://solutionpot.com", //over the network
    clientURL: "https://ifcra.solutionpot.com",
    MAX_FILE_SIZE: 30 * 1024 * 1024
  },
};

export default environment[env];
