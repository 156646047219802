// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-compose {
    border: 1px solid #d0d0d0;
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-family: Arial, sans-serif;
  }
  
  .email-compose-header {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .input-field {
    width: 48%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 12px;
    border-bottom: 2px solid #007bff;
    transition: border-color 0.3s ease-in-out;
  }
  
  .input-field:focus {
    border-color: #0056b3;
  }
  
  .email-compose-body {
    margin-top: 16px;
  }
  
  .textarea-field {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 12px;
    border: 2px solid #d0d0d0;
    border-radius: 6px;
    resize: vertical;
    min-height: 200px;
  }
  
  .send-button {
    background-color: #0C2544;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease-in-out;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Email/EmailPopup.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,wCAAwC;IACxC,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,cAAc;EAChB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,aAAa;IACb,eAAe;IACf,aAAa;IACb,gCAAgC;IAChC,yCAAyC;EAC3C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,6CAA6C;EAC/C","sourcesContent":[".email-compose {\r\n    border: 1px solid #d0d0d0;\r\n    padding: 16px;\r\n    max-width: 600px;\r\n    margin: 0 auto;\r\n    background-color: #fff;\r\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n    border-radius: 6px;\r\n    font-family: Arial, sans-serif;\r\n  }\r\n  \r\n  .email-compose-header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 8px 0;\r\n  }\r\n  \r\n  .input-field {\r\n    width: 48%;\r\n    border: none;\r\n    outline: none;\r\n    font-size: 16px;\r\n    padding: 12px;\r\n    border-bottom: 2px solid #007bff;\r\n    transition: border-color 0.3s ease-in-out;\r\n  }\r\n  \r\n  .input-field:focus {\r\n    border-color: #0056b3;\r\n  }\r\n  \r\n  .email-compose-body {\r\n    margin-top: 16px;\r\n  }\r\n  \r\n  .textarea-field {\r\n    width: 100%;\r\n    border: none;\r\n    outline: none;\r\n    font-size: 16px;\r\n    padding: 12px;\r\n    border: 2px solid #d0d0d0;\r\n    border-radius: 6px;\r\n    resize: vertical;\r\n    min-height: 200px;\r\n  }\r\n  \r\n  .send-button {\r\n    background-color: #0C2544;\r\n    color: #fff;\r\n    border: none;\r\n    padding: 12px 24px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    border-radius: 6px;\r\n    transition: background-color 0.3s ease-in-out;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
