// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assessment-modal .open-btn {
    height: 40px;
    padding: 0 20px;
}
.assessment-modal .open-btn:hover {
    color: #fff;
    opacity: 0.9;
}

.name-of-assesment{
    width: 113px;
    margin-right: 15px;
}

#fileInput {
    display: none;
  }

  a {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/AssessmentModal/AssessmentModal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;EACf;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".assessment-modal .open-btn {\r\n    height: 40px;\r\n    padding: 0 20px;\r\n}\r\n.assessment-modal .open-btn:hover {\r\n    color: #fff;\r\n    opacity: 0.9;\r\n}\r\n\r\n.name-of-assesment{\r\n    width: 113px;\r\n    margin-right: 15px;\r\n}\r\n\r\n#fileInput {\r\n    display: none;\r\n  }\r\n\r\n  a {\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
