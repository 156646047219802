import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import infoIcon from "../../assets/image/i-icon.svg";
import SubmitArrowWhite from "../../assets/image/submit-arrow-white.svg";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import imageArrow from "../../assets/image/logout.svg";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import InfoTip from "../../pages/InfoTip";
import ShowWeight from "../../pages/ShowWeight";
import {
  getConfiguration,
  initiateIFCRA,
} from "../../services/ApiService";
import { isAllApproved } from "../../utils/customHooks/isAllApproved";
import { isPercentageValid } from "../../utils/customHooks/isPercentageValid";
import { showNotification } from "../../utils/customHooks/Toast";
import Layout from "../Common/Layout";
import "./InitiateIFCRA.css";
import MenuMobile from "../../assets/image/menu-2-line.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";


const InitiateIFCRA = () => {
  const contentRef = useRef(null);
  const [inherentData, setInherentData] = useState([]);
  const [controlData, setControlData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isPercentValid, setIsPercentValid] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate()
  const {user, logout} = useAuth()

  // Function to handle user logout
  const handleLogout = () => {
    logout();
    navigate("/login");
  };


  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };

  const handleCloseInformationModal = () => setShowInformationModal(false);

  const fetchData = () => {
    setIsLoading(true); // Set loading state to true
    getConfiguration()
      .then((response) => {
        setInherentData(
          response.data.find((item) => item.name === "Inherent Risk")
        );
        setControlData(
          response.data.find((item) => item.name === "Control Risk")
        );

        setIsReady(isAllApproved(response.data));
        setIsPercentValid(isPercentageValid(response.data));
        setIsLoading(false); // Set loading state to false when data is loaded
      })
      .catch((error) => {
        console.log("There was an issue loading the data.");
        setIsLoading(false); // Set loading state to false when data is loaded
      });
  };

  const initiateIFCRAdata = async () => {
    setSubmitLoading(true); // Set loading state to true

    try {
      setSubmitLoading(true); // Set loading state to true
      // await callRunnerHistory();
      // await thresholdFactor();
      await initiateIFCRA();
      showNotification("iFCRA has been initiated successfully.", "success");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ??
        "Something went wrong, Please try again.";
      showNotification(`${errorMessage}`, "error");
    } finally {
      setSubmitLoading(false); // Set loading state to false when data is loaded
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <h2 className="heading-common exclude-from-pdf">
          <img width={20} height={20} src={MenuMobile} alt="icon" onClick={()=>setIsSidebarVisible((prev)=>!prev)} className="hide-desktop-icon"/> 
            Initiate iFCRA
            {!isLoading && (!isPercentValid || !isReady) && (
              // <OverlayTrigger
              //   placement="bottom-end"
              //   overlay={
              //     <Tooltip id="tooltip-top">
              //       Overall Percentage is not 100%
              //     </Tooltip>
              //   }
              // >
              <small className="fa fa-exclamation-triangle mx-2">
                {!isReady && (
                  <span
                    style={{ fontSize: 16, paddingRight: 10, paddingLeft: 3 }}
                  >
                    Approval is Pending
                  </span>
                )}
                {!isPercentValid && (
                  <span style={{ fontSize: 16, paddingLeft: 3 }}>
                    Overall Percentage is not 100%
                  </span>
                )}
              </small>
              // </OverlayTrigger>
            )}
          </h2>

          <div className="d-flex justify-content-around align-items-center">
            <div className="button-configuration exclude-from-pdf mr-89">
              <InfoTip
                show={!isReady || !isPercentValid}
                info={`${!isReady ? `Approval is Pending` : ""}  ${
                  !isPercentValid ? `Overall Percentage is not 100%` : ""
                }`}
              >
                <button
                  type="button"
                  disabled={!isReady || !isPercentValid || submitLoading}
                  // className="save-btn"
                  className={`save-btn
                    ${
                      !isReady || !isPercentValid || submitLoading
                        ? "disabled disabled-button"
                        : ""
                    }
                  `}
                  onClick={initiateIFCRAdata}
                >
                  {!submitLoading ? (
                    <React.Fragment>
                      <img
                        src={SubmitArrowWhite}
                        alt="icon"
                        className="exclude-from-pdf mx-2"
                      />
                      Submit
                    </React.Fragment>
                  ) : (
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: 24 }}
                    />
                  )}
                </button>
              </InfoTip>
            </div>

            <div className="top-icons-heading">
              <img
                src={infoIcon}
                className="exclude-from-pdf"
                alt="icon"
                onClick={handleShowInformationModal}
                style={{ cursor: "pointer" }}
              />
              <DownloadAsPDF contentRef={contentRef} />
              <EmailPopup contentRef={contentRef} />
              <img
              style={{
                width: "29px",
                height: "29px",
                objectFit: "contain",
                cursor: "pointer",
              }}
              src={imageArrow}
              alt="icon"
              onClick={handleLogout}
            />
            <p>{user.username}</p>
            </div>
          </div>
        </div>

        {isLoading ? (
          <>
            <div className="spinner-container center-spinner">
              <div className="spinner">
                <div className="spinner-border text-secondary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="page-body scroll-cover">
              <div className="row mt-2">
                <div className="col-md-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Assessment Unit</th>
                        <th>Risk Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inherentData.assessment_units?.map((unit) => (
                        <tr key={unit.id}>
                          <td>{unit.name}</td>
                          <td>
                            <ShowWeight
                              overrideWeight={unit.overrideWeight}
                              status={unit.status}
                              weight={unit.weight}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <h5>Inherent Risk Distribution</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Assessment Unit</th>
                        {inherentData?.assessment_units?.length &&
                          inherentData.assessment_units[0]?.risk_categories?.map(
                            (category) => (
                              <React.Fragment key={category.id}>
                                <th>{category.name}</th>
                              </React.Fragment>
                            )
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {inherentData?.assessment_units?.map((unit) => (
                        <tr key={unit.id}>
                          <td>{unit.name}</td>
                          {unit?.risk_categories?.map((category) => (
                            <td key={category.id}>
                              <ShowWeight
                                overrideWeight={category.overrideWeight}
                                status={category.status}
                                weight={category.weight}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <h5>Control Risk Distribution</h5>
                </div>
              </div>

              {controlData?.assessment_units?.map((unit) => (
                <div key={unit.id} className="row mt-2">
                  <div className="col-md-12">
                    <h6>{unit.name}</h6>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Risk Category</th>
                          {unit?.risk_categories?.length &&
                            unit.risk_categories[0]?.sub_categories?.map(
                              (subCategory) => (
                                <React.Fragment key={subCategory.id}>
                                  <th>{subCategory.name}</th>
                                </React.Fragment>
                              )
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {unit?.risk_categories?.map((category) => (
                          <tr key={category.id}>
                            <td>{category.name}</td>
                            {category?.sub_categories?.map((subCategory) => (
                              <td key={subCategory.id}>
                                <ShowWeight
                                  overrideWeight={subCategory.overrideWeight}
                                  status={subCategory.status}
                                  weight={subCategory.weight}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="initiate-ifcra"
      />

      <ToastContainer />
    </Layout>
  );
};

export default InitiateIFCRA;
