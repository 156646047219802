// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-left {
    border-left: solid 1px rgba(0, 0, 0, 0.1);
}
.border-right {
    border-right: solid 1px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/Control-Risk/ControlRisk.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;AACA;IACI,0CAA0C;AAC9C","sourcesContent":[".border-left {\r\n    border-left: solid 1px rgba(0, 0, 0, 0.1);\r\n}\r\n.border-right {\r\n    border-right: solid 1px rgba(0, 0, 0, 0.1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
