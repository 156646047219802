// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doughnutchart canvas {
  padding: 25px;
  width: 180px !important;
  height: 180px !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .doughnutchart canvas{
    padding: 6px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .doughnutchart canvas {
    padding: 0px;
  }
}

@media screen and (min-width: 1600px) {
  .doughnutchart canvas {
    padding: 5px;
    width: 235px !important;
    height: 235px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/DoughnutChart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;IACZ,uBAAuB;IACvB,wBAAwB;EAC1B;AACF","sourcesContent":[".doughnutchart canvas {\r\n  padding: 25px;\r\n  width: 180px !important;\r\n  height: 180px !important;\r\n}\r\n\r\n@media screen and (min-width: 768px) and (max-width: 991px) {\r\n  .doughnutchart canvas{\r\n    padding: 6px;\r\n  }\r\n}\r\n\r\n@media screen and (min-width: 1200px) and (max-width: 1600px) {\r\n  .doughnutchart canvas {\r\n    padding: 0px;\r\n  }\r\n}\r\n\r\n@media screen and (min-width: 1600px) {\r\n  .doughnutchart canvas {\r\n    padding: 5px;\r\n    width: 235px !important;\r\n    height: 235px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
