import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookIcon from "../../assets/image/book-icon.svg";
import { useAuth } from "../../components/Auth/AuthContext";
import {
  downloadAuAttachment,
  downloadKriAttachment,
  downloadRcAttachment,
  updateAssessmentUnit,
  updateKRI,
  updateRiskCategory,
  updateSubCategory,
} from "../../services/ApiService";
import { showNotification } from "../../utils/customHooks/Toast";
import FileUpload from "../FileUpload";
import "./UpdateModal.css";
import environment from "../../environments/environment";

const UpdateModal = ({
  showModal,
  handleCloseModal,
  Data,
  modalType,
  fetchData,
}) => {
  const { user } = useAuth();

  // Initialize the state with default values
  const initialState = {
    overrideWeight: Data.overrideWeight,
    remark: Data.remark,
    status: Data.status,
  };

  const [inputError, setInputError] = useState(""); // State to hold the input error message
  const [isLoading, setIsLoading] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    if (Data) {
      const initialState = {
        overrideWeight: Data.overrideWeight,
        remark: Data.remark,
        status: Data.status,
        newRemark: "",
      };
      setUpdatedData(initialState);
    }
  }, [Data, user.role]);
  const [updatedData, setUpdatedData] = useState(initialState);

  function formatDate(date) {
    date = new Date(date);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = date.toLocaleTimeString("en-US", options); // Get formatted time

    // const day = String(date.getDate()).padStart(2, "0");
    // const monthNames = [
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec",
    // ];
    // const month = monthNames[date.getMonth()];
    // const year = date.getFullYear();

    // return `${time} ${day}-${month}-${year}`;

    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseFloat(inputValue);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 100) {
      setInputError("Override Weight must be between 1 and 100");
    } else {
      setInputError(""); // Clear the error message if input is valid
    }

    setUpdatedData({
      ...updatedData,
      [e.target.name]: inputValue,
    });
  };

  const handleTextareaChange = (name, value) => {
    const data = {
      ...updatedData,
      [name]: value,
    };
    setUpdatedData(data);
  };

  const downloadAttachment = (id) => {
    const processDownload = (response, fileName) => {
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a temporary link and trigger the download without manual file name handling
      const link = document.createElement("a");
      link.href = url;
      console.log("fileName", fileName);

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    switch (modalType) {
      case "assessmentModal":
        downloadAuAttachment(id).then((res) => {
          const contentDisposition = res.headers["content-disposition"];
          const fileName = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/"/g, "")
            : `document.pdf`;
          processDownload(res.data, fileName);
        });
        break;
      case "riskCategoryModal":
        downloadRcAttachment(id).then((res) => {
          const contentDisposition = res.headers["content-disposition"];
          const fileName = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/"/g, "")
            : `document.pdf`;
          processDownload(res.data, fileName);
        });
        break;
      case "kriModal":
        downloadKriAttachment(id).then((res) => {
          const contentDisposition = res.headers["content-disposition"];
          const fileName = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/"/g, "")
            : `document.pdf`;
          processDownload(res.data, fileName);
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("overrideWeight", updatedData.overrideWeight);
    formData.append("remark", updatedData.newRemark);
    formData.append("username", user.username);

    if (selectedAction === "approved") {
      formData.append("status", "Approved");
      setLoader1(true);
    } else if (selectedAction === "rejected") {
      formData.append("status", "Rejected");
      setLoader2(true);
    } else {
      formData.append("status", "Pending");
    }

    if (updatedData.file) {
      formData.append("file", updatedData.file);
    }

    // Define a function to handle the completion of the request
    const handleSuccess = (response) => {
      fetchData();
      handleCloseModal();
      setUpdatedData(initialState);
      showNotification(`Updated Successfully.`, "success");
    };

    // Define a function to handle the error of the request
    const handleError = (error) => {
      showNotification(
        `Failed to update data. Please try again later.`,
        "error"
      );
    };

    // Define a function to handle the completion of the request
    const handleRequestCompletion = () => {
      setIsLoading(false); // Set loading to false when the request is complete
      setLoader1(false);
      setLoader2(false);
    };

    // Based on modalType, different endpoints will be called to update data
    switch (modalType) {
      case "assessmentModal":
        updateAssessmentUnit(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "riskCategoryModal":
        updateRiskCategory(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "subCategoryModal":
        updateSubCategory(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "kriModal":
        updateKRI(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    setUpdatedData({
      ...initialState,
      overrideWeight: 0, // Set overrideWeight to 0 when resetting
    });
  };
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="statistical-box">
              <p>Statistical Weight: {Data.weight}</p>
              <p className="override-field">
                Override Weight: &nbsp;{" "}
                <input
                  type="number"
                  placeholder="0"
                  name="overrideWeight"
                  required
                  value={updatedData.overrideWeight ?? updatedData.risk}
                  onChange={handleInputChange}
                  min={1}
                  max={100}
                />
              </p>
              <small className="text-danger" style={{ fontSize: "12px" }}>
                {inputError}
              </small>
            </div>
            <div>
              {updatedData.remark && (
                <>
                  <div class="table-label">Previous Remarks</div>

                  <table class="modal-table table table-striped">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>File Name</th>
                        <th>Date Created</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedData.remark.map((data) => (
                        <tr>
                          <td>{data.name}</td>
                          <td>
                            {data.uploaded_file ? (
                              <>
                                <OverlayTrigger
                                  placement="bottom-end"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {data.uploaded_file}
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href=""
                                    style={{
                                      border: "none",
                                      justifyContent: "center",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      downloadAttachment(data.id);
                                    }}
                                  >
                                    {data.uploaded_file.slice(0, 8)} ..{" "}
                                    <img
                                      src="/download-btn.svg"
                                      alt=""
                                      style={{
                                        display: "inline-block !important",
                                      }}
                                    />
                                  </a>
                                </OverlayTrigger>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{formatDate(data.created_at)}</td>
                          <td>{data.remark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div className="Write-Justification">
              <div className="inner-body">
                <h3>
                  <img src={BookIcon} alt="icon" /> Write your Justification
                </h3>
                <textarea
                  value={updatedData.newRemark}
                  name="newRemark"
                  cols="96"
                  rows="5"
                  required
                  maxLength={3000}
                  onChange={(e) =>
                    handleTextareaChange("newRemark", e.target.value)
                  }
                  placeholder="Enter your justification here..."
                />
              </div>
            </div>
            <div className="popup-uplod-btn">
              <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                Upload any file type up to a maximum size of{" "}
                {environment.MAX_FILE_SIZE / (1024 * 1024)}MB
              </span>

              <FileUpload setUpdatedData={setUpdatedData} />
              {user.role !== "super-admin" && (
                <>
                  <button type="button" onClick={handleReset}>
                    Reset
                  </button>
                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedData.status === "Approved"
                        ? "disabled disabled-button"
                        : ""
                    }`}
                    onClick={() => setSelectedAction("Pending")}
                    disabled={isLoading || updatedData.status === "Approved"}
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {user.role === "super-admin" && (
                <>
                  <button
                    className={`reset-btn text-white ${
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("rejected")}
                    disabled={
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                    }
                  >
                    {!loader2 ? (
                      "Reject"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>

                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("approved")}
                    disabled={
                      isLoading ||
                      (updatedData.status !== "Pending" &&
                        updatedData.status !== "Approved" &&
                        updatedData.status !== "Rejected")
                    }
                  >
                    {!loader1 ? (
                      "Approve"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UpdateModal;
