// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ForbiddenPage.css */
.forbidden-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f7f9fc;
  }
  
  .forbidden-icon {
    width: 120px;
    height: 120px;
    background-color: #e0e7ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 72px;
    color: #2c3e50;
    margin: 10px 0;
  }
  
  h2 {
    font-size: 24px;
    color: #4a4a4a;
    margin: 10px 0;
  }
  
  p {
    font-size: 16px;
    color: #6b6b6b;
    margin-bottom: 20px;
  }
  
  .back-to-home {
    color: #007bff;
    text-decoration: none;
    font-size: 16px;
    border: 1px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .back-to-home:hover {
    background-color: #007bff;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ForbiddenPage/ForbiddenPage.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":["/* ForbiddenPage.css */\r\n.forbidden-page {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    text-align: center;\r\n    background-color: #f7f9fc;\r\n  }\r\n  \r\n  .forbidden-icon {\r\n    width: 120px;\r\n    height: 120px;\r\n    background-color: #e0e7ff;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 48px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  h1 {\r\n    font-size: 72px;\r\n    color: #2c3e50;\r\n    margin: 10px 0;\r\n  }\r\n  \r\n  h2 {\r\n    font-size: 24px;\r\n    color: #4a4a4a;\r\n    margin: 10px 0;\r\n  }\r\n  \r\n  p {\r\n    font-size: 16px;\r\n    color: #6b6b6b;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .back-to-home {\r\n    color: #007bff;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    border: 1px solid #007bff;\r\n    padding: 10px 20px;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .back-to-home:hover {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
